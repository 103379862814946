import { mapActions, mapState } from 'vuex'
import AdministradorFlota from '@/components/Layout/Menu/AdministradorFlota/AdministradorFlota.vue'
import ReportesCargas from '@/components/Layout/Menu/Reportes/Reportes_cargas/ReportesCargas.vue'
import ReportesClientes from '@/components/Layout/Menu/Reportes/Reportes_clientes/ReportesClientes.vue'
import ReportesAcarreoQr from '@/components/Layout/Menu/Reportes/Reportes_acarreo_qr/ReportesAcarreoQr.vue'
import ReportesBop from '@/components/Layout/Menu/Reportes/Reportes_bop/ReportesBop.vue'
import ReporteEquiposGt from '@/components/Layout/Menu/Reportes/Reporte_equipos_gt/ReporteEquiposGt.vue'
import ReporteEquiposSiniestrosView from '@/components/Layout/Menu/Reportes/Reporte_equipos_siniestros/ReporteEquiposSiniestrosView.vue'
import ReporteAndinaSupermercadoView from '@/components/Layout/Menu/Reportes/Reporte_andina_supermercado/ReporteAndinaSupermercadoView.vue'
import ReporteTransportesRepetidosView from '@/components/Layout/Menu/Reportes/Reporte_transportes_repetidos/ReporteTransportesRepetidosView.vue'
import KPIStatusTransporte from '@/components/Layout/Menu/KPI_status_transporte/KPIStatusTransporte.vue'
import FiltroAdminFlota from '@/components/Layout/Menu/Filtros/Filtro_AdminFlota/FiltroAdminFlota.vue'
import ReasignacionTransporte from '@/components/Layout/Menu/Reasignacion_transporte/ReasignacionTransporte.vue'
import CargasNoAsignadas from '@/components/Layout/Menu/AdministradorFlota/Cargas_no_asignadas/CargasNoAsignadas.vue'
import AdministracionCamiones from '@/components/Layout/Menu/Administracion/Administracion_camiones/AdministracionCamiones.vue'
import AdministracionDestinos from '@/components/Layout/Menu/Administracion/Administracion_destinos/AdministracionDestinos.vue'
import AdministracionAdministradoresView from '@/components/Layout/Menu/Administracion/Administracion_administradores/AdministracionAdministradoresView.vue'
import AdministracionClientesView from '@/components/Layout/Menu/Administracion/Administracion_clientes/AdministracionClientesView.vue'
import AdministracionPatentesView from '@/components/Layout/Menu/Administracion/Administracion_patentes/AdministracionPatentesView.vue'
import AdministracionCargaArchivosView from '@/components/Layout/Menu/Administracion/Administracion_carga_archivos/AdministracionCargaArchivosView.vue'
import AdministracionAyudantesView from '@/components/Layout/Menu/Administracion/Administracion_ayudantes/AdministracionAyudantesView.vue'
import AdministracionUsuariosView from '@/components/Layout/Menu/Administracion/Administracion_usuarios/AdministracionUsuariosView.vue'
import AdministradorFlotaNoProgramadoView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/AdministradorFlotaNoProgramadoView.vue'
import AdministracionTurnosView from '@/components/Layout/Menu/Administracion/Administracion_turnos/AdministracionTurnosView.vue'
import AdministracionConductoresView from '@/components/Layout/Menu/Administracion/Administracion_conductores/AdministracionConductoresView.vue'
import SiniestrosView from '@/components/Layout/Menu/Gestores/Siniestros/SiniestrosView.vue'
import AndinaSupermercadoView from '@/components/Layout/Menu/Gestores/Andina_supermercado/AndinaSupermercadoView.vue'
import ReportesVeraliaView from '@/components/Layout/Menu/Reportes/Reportes_veralia/ReportesVeraliaView.vue'
// import store from '@/store'

export default {
  name: 'Menu',
  components: {
    AdministradorFlota,
    ReportesCargas,
    ReportesClientes,
    ReportesAcarreoQr,
    ReportesBop,
    ReporteEquiposSiniestrosView,
    ReporteAndinaSupermercadoView,
    ReporteTransportesRepetidosView,
    KPIStatusTransporte,
    FiltroAdminFlota,
    ReasignacionTransporte,
    CargasNoAsignadas,
    AdministracionCamiones,
    AdministracionDestinos,
    AdministracionAdministradoresView,
    AdministracionClientesView,
    AdministracionPatentesView,
    AdministracionCargaArchivosView,
    AdministracionAyudantesView,
    AdministracionUsuariosView,
    AdministradorFlotaNoProgramadoView,
    ReporteEquiposGt,
    AdministracionTurnosView,
    AdministracionConductoresView,
    SiniestrosView,
    AndinaSupermercadoView,
    ReportesVeraliaView
  },
  props: {
  },
  data: function () {
    return {
      flagAdminFlotaEnable: false,
      flagAdminFlotaNoProgramadoEnable: false,
      flagReportesClientesEnable: false,
      flagReportesCargasEnable: false,
      flagReportesAcarreoQrEnable: false,
      flagReportesBopEnable: false,
      flagReportesEquiposGtEnable: true,
      flagAdministracionCamiones: false,
      flagAdministracionDestinos: false,
      flagAdministracionAdministradores: false,
      flagAdministracionClientes: false,
      flagAdministracionPatentes: false,
      flagAdministracionCargaArchivos: false,
      flagAdministracionAyudantes: false,
      flagAdministracionUsuarios: false,
      flagAdministracionTurnos: false,
      flagAdministracionConductores: false,
      flagAdministracionPermisos: false,
      flagResportesEquipoSiniestro: false,
      flagReporteAndinaSupermercado: false,
      flagReporteTransportesRepetidos: false,
      flagSiniestros: false,
      flagReportesVeralia: false,
      flagAndinaSupermercado: false,
      KPIStatusObject: {},
      dataKPITransportes: {},
      dataFiltroAdminFlota: {},
      fecha: '',
      flagUpdate: false,
      flagUpdateCargasNoAsigandas: false,
      retraerCargaNoAsignada: false,
      variantRetraer: 'danger'
    }
  },
  watch: {
    dataKPITransportes: function (val) {
      console.log('entra en observador dataKPITransportes')
      if (this.dataKPITransportes !== '') {
        // console.log(this.dataKPITransportes)
        this.KPIStatusObject = this.dataKPITransportes
        // console.log(this.KPIStatusObject)
      }
      this.dataKPITransportes = ''
    },
    flagUpdateCargasNoAsigandas: function (val) {
      console.log('entra en observador flagUpdateCargasNoAsigandas')
      if (this.flagUpdateCargasNoAsigandas) {
        this.flagUpdate = true
        // this.flagUpdate = !this.flagUpdate
        setTimeout(() => { this.flagUpdate = false }, 500)
        // this.flagUpdate = false
        // this.$bvToast.toast('Tabla Actualizada', {
        //   title: 'Estado',
        //   variant: 'success',
        //   solid: true
        // })
        this.flagUpdateCargasNoAsigandas = false
        // this.flagUpdate = false
      }
    },
    dataFiltroAdminFlota: function (val) {
      console.log('entra en observador dataFiltroAdminFlota')
      // if (this.dataFiltroAdminFlota !== {}) {
      // console.log(this.dataFiltroAdminFlota)
      // }
    }
  },
  mounted () {
    const user = this.user
    // const user = 'claudioadrian.camposaburto@gmail.com'
    this.getPermisos(user).then((res) => {
      console.log('cargo pemrisos')
    })
    const date = new Date()
    const fechaActual = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')}`
    this.fecha = fechaActual
  },
  methods: {
    ...mapActions('Permisos', [
      'getPermisos'
    ]),
    retraerCargaNoAsignadaEnable () {
      this.retraerCargaNoAsignada = !this.retraerCargaNoAsignada
      if (this.retraerCargaNoAsignada) {
        this.variantRetraer = 'success'
      } else {
        this.variantRetraer = 'danger'
      }
    },
    Enable (
      flagAdminFlotaEnable,
      flagReportesCargasEnable,
      flagReportesClientesEnable,
      flagAdministracionDestinos,
      flagAdministracionAdministradores,
      flagAdministracionClientes,
      flagAdministracionPatentes,
      flagAdministracionCargaArchivos,
      flagAdministracionAyudantes,
      flagAdministracionCamiones,
      flagAdminFlotaNoProgramadoEnable,
      flagAdministracionUsuarios,
      flagReportesAcarreoQrEnable,
      flagReportesBopEnable,
      flagReportesEquiposGtEnable,
      flagAdministracionTurnos,
      flagAdministracionConductores,
      flagResportesEquipoSiniestro,
      flagReporteAndinaSupermercado,
      flagReporteTransportesRepetidos,
      flagSiniestros,
      flagAndinaSupermercado,
      flagReportesVeralia,
      flagAdministracionPermisos
    ) {
      this.flagAdminFlotaEnable = flagAdminFlotaEnable
      this.flagReportesCargasEnable = flagReportesCargasEnable
      this.flagReportesClientesEnable = flagReportesClientesEnable
      this.flagAdministracionDestinos = flagAdministracionDestinos
      this.flagAdministracionAdministradores = flagAdministracionAdministradores
      this.flagAdministracionClientes = flagAdministracionClientes
      this.flagAdministracionPatentes = flagAdministracionPatentes
      this.flagAdministracionCargaArchivos = flagAdministracionCargaArchivos
      this.flagAdministracionAyudantes = flagAdministracionAyudantes
      this.flagAdministracionCamiones = flagAdministracionCamiones
      this.flagAdminFlotaNoProgramadoEnable = flagAdminFlotaNoProgramadoEnable
      this.flagAdministracionUsuarios = flagAdministracionUsuarios
      this.flagReportesAcarreoQrEnable = flagReportesAcarreoQrEnable
      this.flagReportesBopEnable = flagReportesBopEnable
      this.flagReportesEquiposGtEnable = flagReportesEquiposGtEnable
      this.flagAdministracionTurnos = flagAdministracionTurnos
      this.flagAdministracionConductores = flagAdministracionConductores
      this.flagResportesEquipoSiniestro = flagResportesEquipoSiniestro
      this.flagReporteAndinaSupermercado = flagReporteAndinaSupermercado
      this.flagReporteTransportesRepetidos = flagReporteTransportesRepetidos
      this.flagSiniestros = flagSiniestros
      this.flagAndinaSupermercado = flagAndinaSupermercado
      this.flagReportesVeralia = flagReportesVeralia
      this.flagAdministracionPermisos = flagAdministracionPermisos
    },
    AdminFlotaNoProgramadoEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdminFlotaEnable () {
      this.Enable(true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    ReportesClientes () {
      this.Enable(false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    ReportesCargas () {
      this.Enable(false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionCamionesEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionDestinosEnable () {
      this.Enable(false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionAdministradoresEnable () {
      this.Enable(false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionClientesEnable () {
      this.Enable(false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionPatentesEnable () {
      this.Enable(false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionCargaArchivosEnable () {
      this.Enable(false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionAyudantesEnable () {
      this.Enable(false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    AdministracionUsuariosEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false)
    },
    ReportesAcarreoQrEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false)
    },
    ReportesBopEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false)
    },
    ReportesEquiposGtEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false)
    },
    AdministracionTurnosEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false)
    },
    AdministracionConductoresEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false)
    },
    ReportesEquipoSinistroEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false)
    },
    ReportesAndinaSupermercadoEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false)
    },
    ReportesTransportesRepetidosEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false)
    },
    ReportesSiniestrosEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false)
    },
    GestorAndinaSupermercadoEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false)
    },
    ReportesVeraliaEnable () {
      this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false)
    },
    AdministracionPermisosEnable () {
      // this.Enable(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true)
      window.open(this.hostAbac + '?token=' + this.token, '_blank')
    },
    cerrarSesion () {
      console.log('cerrar sesion')
    },
    onOver (element) {
      this.$refs[element].visible = true
      console.log(element)
      console.log('over')
      console.log(this.$refs[element].visible)
    },
    onLeave (element) {
      // setTimeout(() => { this.$refs[element].visible = false }, 2000)
      this.$refs[element].visible = false
      console.log(element)
      console.log('leave')
      console.log(this.$refs[element].visible)
    },
    onOverAdministracion (element) {
      this.$refs[element].visible = true
      console.log(this.$refs[element].visible)
    },
    onAdministracion (element) {
      // setTimeout(() => { this.$refs[element].visible = false }, 2000)
      this.$refs[element].visible = false
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async time () {
      await this.sleep(1000)
      // for (let i = 0; i < 5; i++) {
      //     console.log(`Waiting ${i} seconds...`);
      //     await sleep(i * 1000);
      // }
      console.log('Done')
    },
    test () {
      console.log('test')
    }
  },
  computed: {
    ...mapState('Permisos', ['permisos']),
    ...mapState(['user']),
    ...mapState(['token']),
    ...mapState(['hostAbac']),
    userInitials () {
      return this.user
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
    },
    avatarSize () {
      return `${this.userInitials.length * 20}px`
    }
  }
}
